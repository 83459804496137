.btn {
    all: unset;

    display: block;
    width: 100%;
    margin-right: var(--gap-half);
    padding: 1em;
    font-size: 1.15em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    line-height: 0.85;
    color: var(--text-color-dark);
    background-color: var(--c-fill-100);
    border-radius: 1.5em;
    box-sizing: border-box;
    cursor: pointer;

    &--with-icon {
        @extend .btn;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        & > span {
            display: block;
            text-align: center;
        }
    }
}
