// ### Reset ###
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: none;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

// HTML5 display definitions
// Corrects block display not defined in IE6/7/8/9 & FF3
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary,
main {
    display: block;
}

// Corrects inline-block display not defined in IE6/7/8/9 & FF3
audio,
canvas,
video {
    display: inline-block;
}

// Addresses margins handled incorrectly in IE6/7
body {
    margin: 0;
}

// Remove list item style
ul {
    list-style-type: none;
}

// Remove the gray background color from active links in IE 10.
a {
    background: transparent;
    text-decoration: none;
}

// Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
button,
input {
    line-height: normal; // 1
}

// 1. Addresses appearance set to searchfield in Safari 5, Chrome
input[type="search"] {
    -webkit-appearance: textfield; // 1
}

// Removes inner padding and search cancel button in S5, Chrome on OS X
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

// Removes inner padding and border in FF3+
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

// 1. Removes default vertical scrollbar in IE6/7/8/9
// 2. Improves readability and alignment in all browsers
textarea {
    overflow: auto; // 1
    vertical-align: top; // 2
}

textarea:focus,
input:focus {
    outline: 0;
}

//
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}
.no-visually {
    position: absolute;
    width: 1;
    height: 1;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
}
