@import "inceptions";
@import "ui";
@import "typography";
@import "screens";

@import "game";

.board-row {
    display: flex;
}

.square {
    width: 100px;
    height: 100px;
    background: #fff;
    border: 1px solid #000;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    margin-bottom: 20px;
    font-size: 24px;
}
