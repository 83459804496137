.sections-wrapper {
    .section {
        margin-bottom: var(--gap-2x);
        padding: var(--gap-2x);
        background-color: var(--fill-12);
        border-radius: 1em;

        .section-group {
            margin-bottom: var(--gap);

            &__title {
                @extend .headline;
            }
            &__content {
                margin-top: var(--gap);
            }

            .btn {
                margin-bottom: var(--gap);
                &:last-child {
                    margin-bottom: 0;
                }
            }
            p {
                font-weight: 600;
                margin: 0.5em 0;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
