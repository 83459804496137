body {
    font: var(--font);
    letter-spacing: 0;
    font-synthesis: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    direction: ltr;
    text-align: left;
    color: var(--c-text);

    user-select: none;
}

// стандартный заголовок
.title {
    // стандартный заголовок (прим. номер доски)
    & {
        font-size: 1.5em;
        font-weight: 1000;
        text-transform: uppercase;
    }
    // стандартный заголовок (прим. имя игрока)
    &--large {
        font-size: 2em;
        font-weight: 1000;
        text-transform: uppercase;
    }
}

// Для заголовков секций и прочего
.headline {
    font-size: 1.0625em;
    font-weight: 800;
    text-transform: uppercase;
}
