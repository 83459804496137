.home {
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;
    gap: var(--gap-2x);
    height: 100%;

    &__brand {
        display: block;
        width: 100%;
        height: 15vh;
        & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        & > span {
            display: block;
            font-size: 0;
            line-height: 0;
            height: 0;
            visibility: hidden;
        }
    }
}

@media (min-height: 750px) {
    .home {
        &__brand {
            margin-top: var(--gap-2x);
            height: 20vh;
        }
    }
}
