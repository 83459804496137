body {
    height: 100dvh;
    overflow: hidden;

    #root {
        height: 100%;
    }
}

.container {
    width: 100%;
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
    padding: var(--gap-2x) var(--gap);
}

.full-height {
}
