.game-screen {
    &__headline {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 30px;
        align-items: center;
        @extend .title;

        & > a {
            color: #fff;
        }
        & > h1 {
            color: var(--c-fill-65);
        }
    }
}

.board {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: var(--gap);
    height: 100%;
    padding: var(--gap) 0;

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        // content: ;
    }
    &__headline {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 30px;
        align-items: center;
        @extend .title;

        & > a {
            color: #fff;
        }
        & > h1 {
            color: var(--c-fill-65);
        }
    }
    &__players {
        display: block;
        height: 100%;
        margin: var(--gap-2x) 0;
        text-align: right;
        list-style: none;
        & > li {
            width: 100%;
            @extend .title--large;
            & > strong {
            }
            & > span {
                margin: 0 0.375em;
                color: var(--c-fill-65);
            }
            & > i {
                position: relative;
                top: 0.0275em;
                font-size: 0.9em;
                color: var(--c-fill-65);
            }
        }
    }
    &__status {
        @extend .title;
        color: var(--c-fill-65);
        i {
            position: relative;
            top: 0.0275em;
            margin-left: var(--gap-half);
            font-size: 0.9em;
            color: var(--c-fill-100);
        }
    }

    &__tile {
        position: relative;
        display: block;
        padding-bottom: 100%;
        border-radius: 25px;
        overflow: hidden;

        & > button {
            all: unset;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            font-size: 2.75em;
            text-align: center;
            background-color: rgba(21, 22, 22, 0.05);
            border-radius: inherit;
            cursor: pointer;

            &:hover {
                background-color: rgba(21, 22, 22, 0.07);
            }
        }
    }
    &[data-size="4"] &__wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
    &[data-size="5"] &__wrapper {
        grid-template-columns: repeat(5, 1fr);
    }
    &[data-size="4"] &__tile {
        border-radius: 18px;
    }
    &[data-size="5"] &__tile {
        border-radius: 14px;
    }
}
