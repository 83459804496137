:root {
    --c-blue: #007aff;
    --c-red: #cb3c52;
    --c-green: #00a84c;
    --c-pink: #ffb6c1;
    --c-orange: #ff6347;

    --c-purple: #6d65c3;

    --c-darkgray: #2c2d2e;

    // Значение копируется из обозначенных выше
    // Также его нужно дублировать в тег meta
    --c-default: #2c2d2e;

    --c-text: #fff;

    --fill-12: rgba(255, 255, 255, 0.12);
    --c-fill-65: rgba(255, 255, 255, 0.65);
    --c-fill-100: rgba(255, 255, 255, 1);

    --font-size: 16px;
    --font: 400 var(--font-size) / 1.45 -apple-system, BlinkMacSystemFont, system-ui, SF Pro Text, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif, arial, Tahoma, verdana;

    --text-color-dark: #343257;
    --text-color-dark-50: rgba(22, 50, 87, 0.5);

    --gap: 1rem;
    --gap-half: 0.5rem;
    --gap-2x: 2rem;
    --gap-4x: 4rem;
}
@media (max-height: 575px) {
    :root {
        --font-size: 13px;
        --gap: 0.5rem;
        --gap-half: 0.275rem;
        --gap-2x: 1.25rem;
        --gap-4x: 1.75rem;
    }
}
@media (max-height: 625px) {
    :root {
        --font-size: 13.5px;
        --gap: 0.65rem;
        --gap-half: 0.375rem;
        --gap-2x: 1.5rem;
        --gap-4x: 2rem;
    }
}

@media (max-height: 659px) {
    :root {
        --font-size: 14px;
    }
}
