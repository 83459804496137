@font-face {
    font-family: "icomoon";
    src: url("../../Fonts/icomoon.eot?z90c8m");
    src: url("../../Fonts/icomoon.eot?z90c8m#iefix") format("embedded-opentype"), url("../../Fonts/icomoon.ttf?z90c8m") format("truetype"), url("../../Fonts/icomoon.woff?z90c8m") format("woff"), url("../../Fonts/icomoon.svg?z90c8m#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"],
[class*=" icon-"] {
    @extend .icon;
}
.icon-arrow-down:before {
    content: "\e900";
}
.icon-arrow-left:before {
    content: "\e901";
}
.icon-arrow-right:before {
    content: "\e902";
}
.icon-arrow-up:before {
    content: "\e903";
}
.icon-rhombus:before {
    content: "\e904";
}
.icon-square:before {
    content: "\e905";
}
.icon-triangle:before {
    content: "\e906";
}
.icon-zero:before {
    content: "\e907";
}
.icon-xmark:before {
    content: "\e908";
}
