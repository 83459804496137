input[type="radio"] {
    width: 0;
    height: 0;
    font-size: 0;
    visibility: hidden;
    display: none;

    & + label {
        display: inline-block;
        margin-right: var(--gap-half);
        margin-bottom: var(--gap-half);
        padding: 0.775em 1.05em;
        font-size: 1.15em;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 0.85;
        color: var(--text-color-dark-50);
        background-color: var(--fill-12);
        border-radius: 0.5em;
        cursor: pointer;
    }
    &:checked + label {
        color: var(--text-color-dark);
        background-color: var(--c-fill-100);
    }
}
